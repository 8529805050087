<template>
  <v-list-item class="pa-0">
    <v-list-item-content class="text-left align-self-start">
      <v-list-item-title class="mb-2">{{ title }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content class="text-right align-self-start">
      <v-list-item-subtitle class="mb-2">{{ value }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['title', 'value']
}
</script>