<template>
  <v-container fluid>
    <PageTitle title="Detail Cashout" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="d-flex justify-space-between">
            <div>
              <v-card-text>
                Merchant Token
              </v-card-text>
              <v-card-title class="pt-0">
                {{ detail?.mid }}
              </v-card-title>
            </div>
            <div>
              <v-card-text>
                Phone Number
              </v-card-text>
              <v-card-title class="pt-0">
                {{ detail?.msisdn }}
              </v-card-title>
            </div>
            <div>
              <v-card-text>
                Status
              </v-card-text>
              <v-card-title class="pt-0">
                {{ detail?.trxStatus }}
              </v-card-title>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Refund Detail</v-card-title>
          <v-card-text>
            <v-list>
              <ListItemHorizontal title="Reference ID" :value="detail?.referenceId" />
              <ListItemHorizontal title="Reference Code" :value="detail?.referenceCode" />
              <ListItemHorizontal title="Merchant Order" :value="detail?.merchantOrderId" />
              <ListItemHorizontal title="Unique Merchant Order ID" :value="detail?.uniqueMerchantOrderId" />
              <ListItemHorizontal title="Admin Fee" :value="detail?.adminFee" />
              <ListItemHorizontal title="Transfered Amount" :value="detail?.transferedAmount" />
              <ListItemHorizontal title="Deducted Amount" :value="detail?.deductedAmount" />
              <ListItemHorizontal title="Description" :value="detail?.description === null ? '-' : detail?.description" />
              <ListItemHorizontal title="Failure Reason" :value="detail?.failureReason" />
              <ListItemHorizontal title="Updated Date" :value="detail?.updatedAt" />
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Bank Account Detail</v-card-title>
          <v-card-text>
            <v-list>
              <ListItemHorizontal title="Bank Name" :value="detail?.BankMaster?.name" />
              <ListItemHorizontal title="Gateway Name" :value="detail?.BankMaster?.gatewayNameAlternativeOy" />
              <ListItemHorizontal title="Nomor Rekening" :value="detail?.bankAccountNo" />
              <ListItemHorizontal title="Nama Pemilik" :value="detail?.bankAccountHolderName" />
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/molecules/PageTitle.vue';
import ListItemHorizontal from '@/components/molecules/ListItemHorizontal.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    PageTitle,
    ListItemHorizontal
  },
  data() {
    return {
      cashoutId: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters('cashoutModule', ['detail']),
  },
  methods: {
    ...mapActions('cashoutModule', ['getDetail'])
  },  
  mounted() {
    this.getDetail(this.cashoutId)
  }
}
</script>